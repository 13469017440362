import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from "@material-ui/core/Link";
import { Typography } from "@material-ui/core";

export default function CustomList(props) {
  const { data, componentNumber } = props;
  const defaultProps = {
    id: `list-${componentNumber}`,
    classes: "",
    listItems: [
      {
        href: "/",
        text: "",
      },
      {
        text: "",
      },
    ],
    isDisableGutters: false,
    isDivider: false,
  };
  const config = { ...defaultProps, ...data };

  return (
    <List className={config.classes}>
      {config.listItems.map((listItem, index) => {
        return (
          <ListItem
            key={index}
            disableGutters={config.isDisableGutters}
            divider={config.isDivider && index !== config.listItems.length - 1}
          >
            {config.isNumbered && (
              <Typography component="span">{index + 1}.&nbsp;</Typography>
            )}
            {listItem.href && <Link href={listItem.href}>{listItem.text}</Link>}
            {!listItem.href && <span>{listItem.text}</span>}
          </ListItem>
        );
      })}
    </List>
  );
}
