import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

import { Capitalize } from "../../utils/capitalize";
import CustomIcon from "../atoms/customIcon";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "30px",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.grey[400]}`,
    textAlign: "center",
  },
  text: {
    marginTop: "1rem",
  },
  heading: {
    margin: "10px 0",
    display: "inline-block",
  },
  subText: {
    margin: "5px 0 10px",
    display: "inline-block",
    fontSize: "0.8rem",
  },
  selectLink: {
    cursor: "pointer",
  },
}));

export default function CustomUpload(props) {
  const { data, componentNumber, callbackFn } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `custom-upload-${componentNumber}`,
   
    heading: "",
    subText: "",
    classes: "",
  };
  const config = { ...defaultProps, ...data };
  const capitalId = Capitalize(config.id);
  config.events = {
    uploadEvent: `onUpload${capitalId}`,
  };

  const onUpload = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.persist();
    callbackFn(config.events.uploadEvent, event);
  };
  const onDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const onDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  /**
   * keyboard tab selection. 
   * Trigger file upload window with enter or space keyboard event
   * @param {*} event 
   */
  const labelHandler = (event) => {
    if (event.which === 32 || event.which === 13) {
      event.preventDefault();
      event.stopPropagation();
      //TODO: Code Review Required - CRR
      document.getElementById("upload").click();
    }
  };

  return (
    <section id={config.id}>
      <span className={classes.heading}>{config.heading}</span>
      <div
        onDrop={onUpload}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        className={classes.container}
      >
        <CustomIcon
          data={{
            name: "cloud_upload",
            size: "xxl",
            variant: "outlined",
          }}
        />
        <div className={classes.text} style={{ color: 'blue' }}>
          If you are experiencing errors after you upload your ID, contact your Physician or Local Health Department to get a copy of your immunization record, or submit an{" "}
          <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="javascript: void(window.open(`https://mcir.org/wp-content/uploads/2014/08/Imm_record_request1.pdf`,`Imm_record_request1`,`resizable,scrollbars,status`));">Official State of Michigan Record Request form</a>
        </div>

        <div className={classes.text}>
          You can upload a photo ID by phone or by computer.
        </div>
        <div className={classes.text}>
         
          <Link
            className={classes.selectLink}
            component="label"
            htmlFor="upload"
            tabindex= "5"            
            onKeyPress={labelHandler} 
            
          >
            Select files
          </Link>{" "}
          or drag and drop files onto this window to upload.
        </div>
      </div>
      <input type="file"  hidden id="upload" onChange={onUpload} />
      <span className={classes.subText}>{config.subText}</span>
    </section>
  );
}
